import { QueryClient, QueryClientProvider } from "react-query";

interface Props {
    children: React.ReactNode
}


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});


function ReactQuery({ children }: Props) {
    return <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>
}


export default ReactQuery