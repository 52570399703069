import type { AppProps } from "next/app";
import "@/styles/global.styles.scss";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import ReactQuery from "@/libs/ReactQuery/ReactQuery";
import Notification from "@/libs/Notification/Notification";



export default function App({ Component, pageProps }: AppProps) {
  return (
    <ReactQuery>
      <Head>
        <title>ModaBU</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Head>
      <NextNProgress color="#fe6f42" showOnShallow={false} options={{
        showSpinner: false,
        trickleSpeed: 0.2
      }} />
      <Notification limit={5} />
      <Component {...pageProps} />
    </ReactQuery>
  );
}


